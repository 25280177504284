/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable prefer-destructuring */

// react imports
import { useEffect, useState } from "react";

// @mui material components
import { Grid, Divider } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// notistack imports
import { useSnackbar } from "notistack";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setCart,
  setEmptyCart,
  setEditOrder,
  setLocalCustomization,
  setChosenAddress,
  setCheckoutEdit,
  setPageState,
} from "context";

// uuid import
import uuid from "react-uuid";

// Firebase imports
import { doc, getDoc, setDoc, collection } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "../../../../../../Firebase";

// sendmail imports
import sendEmail from "../../../../../../SendMail";

function FinalAddress() {
  const auth = getAuth();
  const userInfo = auth.currentUser;
  const [controller, dispatch] = useMaterialUIController();
  const {
    cart,
    cartStudies,
    finalAddress,
    currentStore,
    localCustomization,
    displayName,
    userStores,
  } = controller;
  const { enqueueSnackbar } = useSnackbar();

  const [cartCustomizations, setCartCustomizations] = useState(false);
  const [customizations, setCustomizations] = useState([]);
  const [orderError, setOrderError] = useState(false);

  useEffect(() => {
    const customizedCart = cart.find((obj) => obj.willCustomize === true);
    if (customizedCart !== undefined) {
      setCartCustomizations(true);
    }
    setCustomizations(customizedCart);
  }, [cartCustomizations]);

  useEffect(() => {
    console.log(JSON.stringify({ senderName: "Matt", senderEmail: "matt@qdsolutions.com" }));
  }, []);

  const checkoutWithShipping = async (studies) => {
    const studyArray = Array.from(studies);
    // .toUpperCase().replace(/ /g, "_"))

    const today = new Date();

    studyArray.forEach(async (study) => {
      const studyUpperCase = study.toUpperCase().replace(/ /g, "_");
      const numberGen = uuid().substring(0, 3);
      const orderID = `QD${numberGen}`;
      const siteRef = doc(db, "USERS", userInfo.uid, "STUDIES", studyUpperCase);
      const userSite = await getDoc(siteRef);
      const userRef = doc(db, "USERS", userInfo.uid);
      const userDoc = await getDoc(userRef);
      const pm = userDoc.data().pm.email;
      const sponsor = userDoc.data().sponsor;

      const itemsArray = [];
      cart
        .filter((item) => item.study === study)
        .map((object) =>
          itemsArray.push({
            id: object.newID === undefined ? object.id : object.newID,
            newID: object.newID,
            image: object.image,
            title: object.title,
            quantityOptions: object.quantityOptions,
            cartQuantity: object.cartQuantity || 0,
            language: object.language,
            languageVersion: object.languageVersion,
            willCustomize: object.willCustomize,
            customizationRequired: object.customizationRequired,
            customizationItems:
              object.customizationItems === undefined ? [] : object.customizationItems,
            meta:
              object.meta[object.language] === undefined
                ? object.meta
                : object.meta[object.language],
          })
        );

      const initialStatusTimeline = [
        {
          id: uuid().substring(0, 10),
          status: "new",
          statusTitle: "New Order",
          statusDetails: "This is a new order",
          statusColor: "secondary",
          statusIcon: "border_color",
          timeStamp: today,
          comment: { title: "", details: "" },
          isLast: false,
          tracking: {
            link: "",
            name: "",
            numb: "",
          },
        },
      ];

      const userData = userSite && typeof userSite.data === "function" ? userSite.data() : {};

      const docData = {
        orderFor: userInfo.email,
        orderId: orderID,
        date_created: today,
        date_modified: today,
        storeData: {
          title: study,
          sponsor: sponsor,
          study: studyUpperCase,
          siteNumb: userData ? userData.siteNumb : "",
        },
        customization: {
          customized: localCustomization.customized,
          clinicName: localCustomization.clinicName,
          line2: localCustomization.line2,
          line3: localCustomization.line3,
        },
        shippingAddress: {
          attn: finalAddress.attn,
          email: finalAddress.email,
          phone: finalAddress.phone,
          clinic: finalAddress.clinic,
          addressLine1: finalAddress.addressLine1,
          addressLine2: finalAddress.addressLine2,
          addressLine3: finalAddress.addressLine3,
          city: finalAddress.city,
          state: finalAddress.state,
          zip: finalAddress.zip,
          country: finalAddress.country,
        },
        items: itemsArray,
        status: "new order",
        statusColor: "secondary",
        statusTimeline: initialStatusTimeline,
      };

      if (cart.length === 0) {
        setOrderError(true);
        const variant = "error";
        enqueueSnackbar("Your cart is empty. Add some items and try again!", {
          variant,
          autoHideDuration: 2000,
        });
      } else {
        const autoIdRef = doc(collection(db, `${studyUpperCase}_ORDERS`));

        await setDoc(autoIdRef, docData);

        await sendEmail({
          senderName: displayName,
          senderEmail: userInfo.email,
          senderOrderNumb: orderID,
          senderStudy: study,
          senderRoute: "order-placed-user",
          senderPM: pm,
        });

        await sendEmail({
          senderName: displayName,
          senderEmail: userInfo.email,
          senderOrderNumb: orderID,
          senderStudy: study,
          senderRoute: "order-placed-pm",
          senderPM: pm,
        });

        window.localStorage.removeItem("cart");
        setCart(dispatch, []);
        setEmptyCart(dispatch, true);
        const clearlocalCustomization = { customized: false, clinicName: "", line2: "", line3: "" };
        setLocalCustomization(dispatch, clearlocalCustomization);
        window.localStorage.setItem("customization", JSON.stringify(clearlocalCustomization));
        const clearAddress = {
          nickName: "",
          attn: "",
          clinic: "",
          addressLine1: "",
          addressLine2: "",
          addressLine3: "",
          city: "",
          state: "",
          zip: "",
          country: "United States",
        };
        setChosenAddress(dispatch, clearAddress);
        window.localStorage.setItem("chosenAddress", JSON.stringify(clearAddress));
        setEditOrder(dispatch, false);
        const variant = "success";
        enqueueSnackbar("Your order has been placed!", {
          variant,
          autoHideDuration: 2000,
        });
        setPageState(dispatch, "ThankYou");
      }
    });
  };

  useEffect(() => {
    console.log(currentStore);
  }, [currentStore]);
  useEffect(() => {
    console.log(finalAddress);
  }, [finalAddress]);

  return (
    <MDBox>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <MDBox py={2} px={2} borderRadius={5} bgColor="light">
            <MDTypography variant="h6">Shipping Information</MDTypography>
            <MDTypography variant="body2" sx={{ fontSize: ".75rem" }}>
              {finalAddress.email}
              <br />
              {finalAddress.phone}
              <br />
              <Divider sx={{ margin: ".2rem" }} />
              Attention: {finalAddress.attn}
              <br />
              {finalAddress.addressLine1}
              <br />
              {finalAddress.addressLine2.length === 0 ? (
                ""
              ) : (
                <>
                  {finalAddress.addressLine2} <br />
                </>
              )}
              {finalAddress.addressLine3.length === 0 ? (
                ""
              ) : (
                <>
                  {finalAddress.addressLine3} <br />
                </>
              )}
              {finalAddress.city}, {finalAddress.state}
              <br />
              {finalAddress.zip}
              <br />
              {finalAddress.country}
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid item xs={12} sm={4}>
          <MDBox py={2} px={2} borderRadius={5}>
            <MDTypography variant="h6">Customization</MDTypography>
            {cartCustomizations ? (
              <>
                {customizations.customizationItems.map((item) => (
                  <MDTypography key={item.newID} variant="body2" sx={{ fontSize: ".75rem" }}>
                    {item.title}: {item.item}
                    <br />
                  </MDTypography>
                ))}
              </>
            ) : (
              <MDTypography variant="body2" sx={{ fontSize: ".75rem" }}>
                No Customization
              </MDTypography>
            )}
          </MDBox>
        </Grid>
      </Grid>
      {orderError && (
        <MDBox py={2}>
          <MDTypography variant="body2" color="error">
            There&apos;s something wrong with your order or your cart is empty. Please try again.
          </MDTypography>
        </MDBox>
      )}
      <Grid container sx={{ textAlign: "center" }} spacing={2}>
        <Grid item xs={6}>
          <MDBox py={2}>
            <MDButton
              variant="outlined"
              color="secondary"
              onClick={() => {
                setEditOrder(dispatch, true);
                setCheckoutEdit(dispatch, true);
                setPageState(dispatch, "Checkout");
              }}
              fullWidth
            >
              Edit Order
            </MDButton>
          </MDBox>
        </Grid>
        <Grid item xs={6}>
          <MDBox py={2}>
            <MDButton
              color="info"
              onClick={() => {
                checkoutWithShipping(cartStudies);
              }}
              fullWidth
            >
              Checkout
            </MDButton>
          </MDBox>
        </Grid>
      </Grid>
      <Divider />
    </MDBox>
  );
}

export default FinalAddress;
